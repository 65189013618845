import FuseNavigation from "@fuse/core/FuseNavigation";
import clsx from "clsx";
import { memo, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNavigation,
  selectNavigation,
  setNavigation,
} from "app/store/fuse/navigationSlice";
import { selectNotificationPanelState } from "./notificationPanel/store/stateSlice";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarCloseMobile } from "app/store/fuse/navbarSlice";
import jwtService from "src/app/auth/services/jwtService";
import { useIdleTimer } from "react-idle-timer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { selectUser } from "app/store/userSlice";
import { Button } from "@mui/material";

function Navigation(props) {
  const user = useSelector(selectUser);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const notificationPanelState = useSelector(selectNotificationPanelState);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigation);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [newActionsCount, setNewActionsCount] = useState();

  const getNewActionsCount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Actions?status=0&pageIndex=1&pageSize=1`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setNewActionsCount(data.totalItems);
      dispatch(setNavigation(navigationConfig));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.roles.includes("SuperAdmin")) {
      setIsSuperAdmin(true);
    }
    if (user?.roles.includes("Admin") || user?.roles.includes("SuperAdmin")) {
      setIsAdmin(true);
    }
  }, [user]);

  const navigationConfig = [
    {
      id: "reklam",
      title: "Reklam",
      subtitle: "Reklam işlemleri",
      type: "group",
      icon: "heroicons-outline:cube",
      children: [
        {
          id: "reklamverenler",
          title: "Reklamverenler",
          type: "item",
          url: "advertisers",
          end: true,
        },
        {
          id: "custom-kpi",
          title: "Tanımlamalar",
          type: "item",
          url: "definitions",
          end: true,
        },
        {
          id: "oneriler",
          title: "Öneriler",
          type: "collapse",
          url: "actions/new",
          children: [
            {
              id: "new-actions",
              title: "Yeni Öneriler",
              type: "item",
              url: "/actions/new",
              badge: {
                title: newActionsCount,
                classes: "px-8 bg-pink-600 text-white rounded-full",
              },
            },
            {
              id: "implemented-actions",
              title: "Uygulanan Öneriler",
              type: "item",
              url: "/actions/implemented",
            },
            {
              id: "planned-actions",
              title: "Planlanan Öneriler",
              type: "item",
              url: "/actions/planned",
            },
            {
              id: "ignored-actions",
              title: "Yoksayılan Öneriler",
              type: "item",
              url: "/actions/ignored",
            },
          ],
        },
        {
          id: "islem-gecmisi",
          title: "İşlem Geçmişi",
          type: "item",
          url: "activities",
        },
      ],
    },
    {
      id: "butce",
      title: "Bütçe Raporu",
      subtitle: "Bütçe Raporu İşlemleri",
      type: "group",
      icon: "heroicons-outline:cube",
      children: [
        {
          id: "butce-raporu",
          title: "Bütçe Onayları",
          type: "item",
          url: "budget-report",
        },
        {
          id: "palasilan-butce-raporu",
          title: "Google Paylaşılan Bütçeler",
          type: "item",
          url: "shared-budget-report",
        },
      ],
    },
    isAdmin && {
      id: "kullanici",
      title: "Kullanıcı",
      subtitle: "Kullanıcı işlemleri",
      type: "group",
      icon: "heroicons-outline:cube",
      children: [
        {
          id: "kullanicilar",
          title: "Kullanıcılar",
          type: "item",
          url: "users",
        },
      ],
    },
    {
      id: "system",
      title: "Sistem",
      subtitle: "Sistem işlemleri",
      type: "group",
      icon: "heroicons-outline:cube",
      children: [
        {
          id: "categories",
          title: "Kategoriler",
          type: "item",
          url: "categories",
        },
        {
          id: "tags",
          title: "Etiketler",
          type: "item",
          url: "tags",
        },
        isSuperAdmin && {
          id: "configuration",
          title: "Konfigürasyon Paneli",
          type: "item",
          url: "configuration",
        },
      ],
    },
  ];

  useEffect(() => {
    getNewActionsCount();
  }, [newActionsCount]);

  useEffect(() => {
    if (!notificationPanelState) {
      getNewActionsCount();
    }
  }, [notificationPanelState]);

  // Idle timer start
  const handleAutoLogOut = async () => {
    setOpen(false);
    await fetch(`${process.env.REACT_APP_CONNECT_URL}/logout`, {
      method: "POST",
      headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
    });
    window.location.reload();
  };
  const [open, setOpen] = useState(false);
  const [modalTimer, setModalTimer] = useState(60);
  const logoutRef = useRef(false); // Use useRef instead of useState

  let intervalId = null; // Declare a variable to store the interval ID
  const handleClose = async () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval when the modal is closed
      intervalId = null; // Reset the interval ID
    }
    setModalTimer(10); // Reset the modal timer
    logoutRef.current = false; // Set logoutRef.current to false
  };

  const onIdle = async () => {
    if (!open) {
      // Only reset modalTimer and start a new interval if open is false
      if (intervalId) {
        clearInterval(intervalId); // Clear the previous interval
      }
      setModalTimer(10);
      setOpen(true);
      logoutRef.current = false; // Reset logoutRef.current to false
      intervalId = setInterval(() => {
        // Store the interval ID
        setModalTimer((prev) => prev - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (modalTimer < 1) {
      logoutRef.current = true; // Set logoutRef.current to true
    }
  }, [modalTimer]);

  useEffect(() => {
    if (logoutRef.current && open) {
      // Check that open is true
      handleAutoLogOut();
    }
  }, [logoutRef.current, open]); // Depend on logoutRef.current and open

  useIdleTimer({
    onIdle,
    timeout: 60000 * 60, // 1 hour
    // timeout: 5000, // 5 seconds
    throttle: 500,
  });
  // Idle timer end

  return useMemo(() => {
    function handleItemClick() {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="absolute top-1/2 left-1/2 bg-white p-28 max-w-[700px] shadow-lg rounded-lg"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Oturum Sonlandırma Uyarısı
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Oturumun 1 saat boyunca aktif olmadığı tespit edildi. Aşağıdaki
              butona basarak oturumunuzu devam ettirebilirsiniz. Aksi taktirde
              oturum otomatik olarak sonlandırılacaktır.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              className="mt-16"
            >
              Oturuma Devam Et ({modalTimer})
            </Button>
          </div>
        </Modal>
        <FuseNavigation
          className={clsx("navigation", props.className)}
          navigation={navigationConfig}
          layout={props.layout}
          dense={props.dense}
          active={props.active}
          onItemClick={handleItemClick}
        />
      </>
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
    open,
    modalTimer,
  ]);
}

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
