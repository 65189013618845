import React, { useState, useEffect } from "react";
import JwtService from "../../auth/services/jwtService";
import {
  Drawer,
  Tabs,
  Tab,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { closeBudgetDetailDrawer } from "app/store/fuse/budgetDetailSlice";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import moment from "moment";

const detailsConfig = {
  BudgetApprovals: [
    { label: "Company Name", field: "advertiser.displayName" },
    { label: "Account Name", field: "accountName" },
    { label: "ID", field: "id" },
    { label: "Account ID", field: "accountId" },
    { label: "Advertiser ID", field: "advertiserId" },
    { label: "Campaign ID", field: "campaignId" },
    { label: "Account ID", field: "accountId" },
    { label: "Budget ID", field: "campaignBudgetId" },
    { label: "Currency", field: "advertiser.currencyCode" },
    { label: "Old Budget", field: "oldBudget" },
    { label: "New Budget", field: "newBudget" },
    { label: "Source", field: "source" },
    { label: "Timezone", field: "advertiser.timeZone" },
  ],
  SharedBudgetApprovals: [
    { label: "Company Name", field: "advertiser.displayName" },
    { label: "Account Name", field: "accountName" },
    { label: "ID", field: "id" },
    { label: "Account ID", field: "accountId" },
    { label: "Advertiser ID", field: "advertiserId" },
    { label: "Budget ID", field: "budgetId" },
    { label: "Budget Amount", field: "amountMicros" },
    { label: "Timezone", field: "advertiser.timeZone" },
    { label: "Campaigns", field: "-" },
    { label: "Campaign Name", field: "campaigns[0]?.displayName" },
    { label: "Campaign ID", field: "campaigns[0]?.campaignId" },
    { label: "Campaign Name", field: "campaigns[1]?.displayName" },
    { label: "Campaign ID", field: "campaigns[1]?.campaignId" },
  ],
};

const approvalConfig = {
  BudgetApprovals: [
    { label: "Status", field: "isAccepted" },
    { label: "Approval Date", field: "approvalDate" },
    { label: "Approved By", field: "approvedBy" },
  ],
  SharedBudgetApprovals: [
    { label: "Approval Status", field: "status" },
    { label: "Reviewed By", field: "reviewedBy" },
  ],
};

function BudgetDetailDrawer() {
  const dispatch = useDispatch();
  const { open, data, apiEndpoint } = useSelector(
    (state) =>
      state.fuse?.budgetDetail || { open: false, data: null, apiEndpoint: "" }
  );

  const [activeTab, setActiveTab] = useState(0);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data || !data?.id) return;
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/${apiEndpoint}/${data.id}/Logs`, {
      headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
    })
      .then((res) => res.json())
      .then((data) => setLogs(data || []))
      .catch((err) => {
        console.error("Error fetching logs:", err);
        setLogs([]);
      })
      .finally(() => setLoading(false));
  }, [data]);

  const handleApproval = (status) => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/${apiEndpoint}/${data.id}/Approve`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtService.getAccessToken()}`,
        },
        body: JSON.stringify({ isAccepted: status }),
      }
    )
      .then(() => dispatch(closeBudgetDetailDrawer()))
      .catch((err) => console.error("Error updating approval:", err))
      .finally(() => setLoading(false));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => dispatch(closeBudgetDetailDrawer())}
      sx={{ width: "40%" }}
    >
      {
        (console.log("Drawer open status: ", open),
        console.log("Drawer data: ", data))
      }
      <Tabs
        value={activeTab}
        onChange={(e, newVal) => setActiveTab(newVal)}
        variant="fullWidth"
      >
        <Tab
          label={
            apiEndpoint === "BudgetApprovals"
              ? "Campaign Details"
              : "Budget Details"
          }
        />
        <Tab label="Approval Status" />
        <Tab label="Approval Logs" />
      </Tabs>

      {loading ? (
        <FuseLoading />
      ) : (
        <div className="p-4">
          {activeTab === 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {detailsConfig[apiEndpoint]?.map((item, index) => (
                    <TableRow
                      key={item.field}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                      }}
                    >
                      <TableCell>
                        <strong>{item.label}</strong>
                      </TableCell>
                      <TableCell>{data?.[item.field] || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {activeTab === 1 && (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {approvalConfig[apiEndpoint]?.map((item, index) => (
                    <TableRow
                      key={item.field}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                      }}
                    >
                      <TableCell>
                        <strong>{item.label}</strong>
                      </TableCell>
                      <TableCell>{data?.[item.field] || "Pending"}</TableCell>
                    </TableRow>
                  ))}
                  <div className="flex w-full justify-start mt-4 mx-auto gap-10">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleApproval(true)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleApproval(false)}
                    >
                      Reject
                    </Button>
                  </div>
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {activeTab === 2 && logs.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((log, index) => (
                    <TableRow
                      key={log.id}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                      }}
                    >
                      <TableCell>{log.user}</TableCell>
                      <TableCell>
                        {moment(log.date).format("DD.MM.YYYY HH:mm")}
                      </TableCell>
                      <TableCell>{log.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : activeTab === 2 ? (
            <Typography variant="body2" className="p-4">
              No logs available.
            </Typography>
          ) : null}
        </div>
      )}
    </Drawer>
  );
}

export default BudgetDetailDrawer;
