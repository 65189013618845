import React, { useEffect, useState } from "react";
import JwtService from "../auth/services/jwtService";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TableSortLabel,
  Tooltip,
  TextField,
  TableContainer,
  Paper,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openBudgetDetailDrawer } from "app/store/fuse/budgetDetailSlice";
import { styled } from "@mui/system";
import BudgetDetailDrawer from "./Drawer/BudgetDetailDrawer";

export const getNestedValue = (obj, field) => {
  if (typeof field === "function") {
    return field(obj);
  }
  let value =
    typeof field === "function"
      ? field(obj)
      : field
          .split(".")
          .reduce(
            (acc, key) => (acc && acc[key] !== undefined ? acc[key] : ""),
            obj
          );

  if (
    field === "accountId" &&
    typeof value === "string" &&
    typeof value === "number" &&
    value.length === 10
  ) {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return value || "-";
};

const StatusCell = styled(TableCell)(({ status }) => ({
  backgroundColor:
    status === "Accepted"
      ? "#c8e6c9"
      : status === "Rejected"
      ? "#ffcdd2"
      : "#fff3cd",
  color:
    status === "Accepted"
      ? "#2e7d32"
      : status === "Rejected"
      ? "#c62828"
      : "#856404",
  fontWeight: "bold",
  textAlign: "center",
}));

function BudgetReport({ apiEndpoint, columns }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState({ id: "", direction: "desc" });
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${apiEndpoint}?pageSize=10000`,
        {
          headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
        }
      );
      const result = await response.json();
      console.log(`Fetched data from ${apiEndpoint}:`, result);
      setData(result.items);
      setFilteredData(result.items);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiEndpoint]);

  useEffect(() => {
    const filtered = data.filter((item) =>
      columns.some(({ field }) =>
        getNestedValue(item, field)
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchTerm, data]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / pageSize));
    setPageIndex(1);
  }, [pageSize, filteredData]);

  useEffect(() => {
    const startIndex = (pageIndex - 1) * pageSize;
    setDisplayData(filteredData.slice(startIndex, startIndex + pageSize));
  }, [pageIndex, pageSize, filteredData]);

  const handleRequestSort = (property) => {
    const isAsc = order.id === property && order.direction === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder({ id: property, direction });
    sortData(property, direction);
  };

  const sortData = (property, direction) => {
    const sorted = [...data].sort((a, b) => {
      const aValue = getNestedValue(a, property) || "";
      const bValue = getNestedValue(b, property) || "";

      if (!isNaN(aValue) && !isNaN(bValue)) {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      return direction === "asc"
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
    setData(sorted);
  };

  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    console.log("Clicked Row Data: ", row);
    dispatch(openBudgetDetailDrawer({ data: row, apiEndpoint }));
  };

  return (
    <div className="px-10 py-10 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          id="pageSize"
          value={pageSize}
          onChange={(e) => setPageSize(parseInt(e.target.value))}
          className="px-4 py-2 border border-gray-300 rounded"
        >
          {[10, 25, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <TableContainer component={Paper} className="max-h-[75vh] overflow-auto">
        <Table stickyHeader className="min-w-full">
          <TableHead className="bg-gray-100">
            <TableRow>
              {columns.map(({ label, field }) => (
                <TableCell
                  key={field}
                  align="center"
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "#f5f5f5",
                    zIndex: 2,
                  }}
                >
                  <Tooltip title="Sort">
                    <TableSortLabel
                      active={order.id === field}
                      direction={order.direction}
                      onClick={() => handleRequestSort(field)}
                    >
                      {label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(item)}
                style={{ cursor: "pointer" }}
              >
                {columns.map(({ field }) =>
                  field === "isAccepted" ? (
                    <StatusCell
                      key={field}
                      status={
                        item[field] === null
                          ? "Pending"
                          : item[field]
                          ? "Accepted"
                          : "Rejected"
                      }
                    >
                      {item[field] === null
                        ? "Pending"
                        : item[field]
                        ? "Accepted"
                        : "Rejected"}
                    </StatusCell>
                  ) : (
                    <TableCell key={field} align="center">
                      {getNestedValue(item, field)}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex gap-4 justify-center mt-6 items-center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={pageIndex === 1}
        >
          Previous
        </Button>
        <span>{`Page ${pageIndex} of ${totalPages}`}</span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={pageIndex === totalPages}
        >
          Next
        </Button>
      </div>
      <BudgetDetailDrawer />
    </div>
  );
}

export default BudgetReport;
